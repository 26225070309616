//* Estilos dos Botoes da Aplicacao //
.btn-xs {
  padding: 3px 10px!important;
  background-color: #005c9b;
  color: #fff;
  border-radius: 4px; border: 0}
.btn-icon-xs {
  padding: 3px 8px!important;
  background-color: transparent;
  color: #fff;
  border-radius: 4px; border: 0;
  display: inline-flex;
  cursor: pointer;
  .mat-icon {
    background-color: #005c9b;
    height: 18px !important;
    width: 18px !important;
    font-size: 18px !important;
    border-radius: 5px;
    padding: 3px;
  }
}

.btn-autocomplete-plus {
  font-size: 21px; height: 30px; width: 30px;
  border-radius: 4px; border: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content:center!important; ;
  color: #005b9b!important;
  cursor: pointer;
  margin: 0;
  .mat-icon {
    background-color: #005c9b;
    height: 18px !important;
    width: 18px !important;
    font-size: 18px !important;
    border-radius: 5px;
    padding: 3px;
  }
}


.btn-estado {
  width: 56px;
  height: 24px;
  border-radius: 2rem;
  display: flex;
  align-content: center;
  align-items: center;
}


.btn-estado {
  background-color: #eee; border-radius: 20px
}
.btn-table {
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  overflow: visible;
  align-content: center!important;
  align-items: center!important;
  justify-content: center;
  height: 24px!important;
  cursor: pointer;
  background-color: #eee;
  border-radius: 20px!important;
  transition: background-color .4s ease-in-out;
  &:hover {
    background: #005b9b !important;
    color: #fff;
  }
}